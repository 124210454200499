import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Grid from '@material-ui/core/Grid'
import Spinner from 'react-spinner-material'
import Layout from '../components/layout'
import Seo from '../components/seo'

const BASE_API = process.env.BASE_API
const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class VerificationPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
      emailverifycode: 0,
      emailverifyemail:'',
      usermessage:''
    }
    this.changeFormValue = this.changeFormValue.bind(this)
    this.verfiyemailcode = this.verfiyemailcode.bind(this)
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }
  changeFormValue(event) {
    if (event.target.id === 'emailverifycode') {
      this.setState({emailverifycode: event.target.value})
    }
    if (event.target.id === 'emailverifyemail') {
      this.setState({emailverifyemail: event.target.value})
    }
    console.log(this.state)  
  }
  verfiyemailcode = async (event) => {
    var emailverified = false;
    event.preventDefault()    
    var verifycodecheck = {
      verifycode: this.state.emailverifycode,
      emailaddress: this.state.emailverifyemail,
    }
    let headers = new Headers()
    headers.append('Content-Type', 'text/html')
    headers.append('Origin', '*')
    var requestConfig = {
      method: 'POST',
      body: JSON.stringify(verifycodecheck),
      mode: 'cors',
      cache: 'no-cache',
      headers: headers,
    }    
    fetch(BASE_API + 'usermgmt_verifyemail', requestConfig)
    .then((response) => response.json())
    .then((data) => {
      
      if(JSON.parse(data.body)['result']){
        emailverified = true;
        navigate('/emailverified')
      } else {          
        this.setState({usermessage:JSON.parse(data.body)['errormsg']})        
      }
    })
    .catch((error) => {
        console.log(error)
    })    
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'Email Verification Code'
    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }
    var applycodebutton = (
        <button type="submit" className="promocode_button">
            Verify Email
        </button>
    )

    const verificationpage = (
        <Grid container spacing={0} className="verifycode_container">
            <Grid item xs={2} />
            <Grid item xs={8} >
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <h1 className="generalpage_majorheading">{pageName}</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            Enter your email verification code in the section below and click the Verify Email button. 
                            If you haven't received a code or have any problems please send an email to <span style={{color:"#4648ff", fontStyle:"italic"}}>admin@edgeforecaster.com</span> 
                            from the email address you signed up with. 
                        </div>
                    </Grid>
                    <Grid item xs={12} className="verifycodeform" style={{paddingTop:'5vmin'}}>
                        <form id="promocode-form" method="post" style={{ width: '100%' }} onSubmit={this.verfiyemailcode}>
                            <Grid container spacing={0} style={{ width: '100%' }}>
                              <Grid item xs={2} sm={2} className="verifycode_heading">                                    
                                <h4>Email:  </h4>
                              </Grid>                             
                              <Grid item xs={6} sm={6}>
                                  <input
                                      type="text"
                                      id="emailverifyemail"
                                      placeholder="Email Verification Email"
                                      className="promocode_input"
                                      name="emailverifyemail"
                                      onChange={this.changeFormValue}/>
                              </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ width: '100%' }}>
                              <Grid item xs={2} sm={2} className="verifycode_heading">     
                                <h4 className="verifycode_heading">Code:  </h4>
                              </Grid>                             
                              <Grid item xs={3} sm={3}>
                                  <input
                                      type="text"
                                      id="emailverifycode"
                                      placeholder="Email Verification Code"
                                      className="promocode_input"
                                      name="emailverifycode"
                                      onChange={this.changeFormValue}/>
                              </Grid>
                              <Grid item xs={1} sm={1}/>
                              <Grid item xs={4} sm={4}>                                    
                                  {applycodebutton}
                              </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ width: '100%' }}>
                              <Grid item xs={12} sm={12}>
                              <span className="signup_validationmsg">
                                {this.state.usermessage}
                              </span>
                              </Grid>
                            </Grid>
                        </form>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={2} />                
        </Grid>        
    )
    const verificationpage_mob = (
        <Grid container spacing={0} className="verifycode_container">
            <Grid item xs={12} >
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <h1 className="generalpage_majorheading">{pageName}</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            Enter your email verification code in the section below and click the Verify Email button. 
                            If you haven't received a code or have any problems please send an email to <span style={{color:"#4648ff", fontStyle:"italic"}}>admin@edgeforecaster.com</span> 
                            from the email address you signed up with. 
                        </div>
                    </Grid>
                    <Grid item xs={12} className="verifycodeform" style={{paddingTop:'5vmin'}}>
                        <form id="promocode-form" method="post" style={{ width: '100%' }} onSubmit={this.verfiyemailcode}>
                            <Grid container spacing={0} style={{ width: '100%' }}>
                              <Grid item xs={2} sm={2} className="verifycode_heading">                                    
                                <h4>Email:  </h4>
                              </Grid>                             
                              <Grid item xs={5} sm={5}>
                                  <input
                                      type="text"
                                      id="emailverifyemail"
                                      placeholder="Email Verification Email"
                                      className="promocode_input"
                                      name="emailverifyemail"
                                      onChange={this.changeFormValue}/>
                              </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ width: '100%' }}>
                              <Grid item xs={2} sm={2} className="verifycode_heading">     
                                <h4 className="verifycode_heading">Code:  </h4>
                              </Grid>                             
                              <Grid item xs={3} sm={3}>
                                  <input
                                      type="text"
                                      id="emailverifycode"
                                      placeholder="Email Verification Code"
                                      className="promocode_input"
                                      name="emailverifycode"
                                      onChange={this.changeFormValue}/>
                              </Grid>
                              <Grid item xs={1} sm={1}/>
                              <Grid item xs={4} sm={4}>                                    
                                  {applycodebutton}
                              </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ width: '100%' }}>
                              <Grid item xs={12} sm={12}>
                              <span className="signup_validationmsg">
                                {this.state.usermessage}
                              </span>
                              </Grid>
                            </Grid>
                        </form>
                    </Grid>

                </Grid>
            </Grid>                    
        </Grid>        
    )

    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
          <div className="page_wrapper generalpage_wrapper">            
            {isMobile ? verificationpage_mob : verificationpage}
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(VerificationPage)
export const VerificationPageQuery = graphql`
  query VerificationPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
